import React, { ComponentProps } from 'react';
import { ButtonSignupLogin } from '@hungryroot/components-customer';
import { islandRoot } from '../IslandRoot';
import styles from './ButtonSignupLoginIsland.module.css';

export const ButtonSignupLoginIsland = islandRoot(function (
  props: ComponentProps<typeof ButtonSignupLogin>
) {
  return <ButtonSignupLogin {...props} className={styles.login} />;
});
