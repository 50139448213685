import React from 'react';
import { Button, ButtonProps } from '@hungryroot/ramen';
import { AuthState, useIdentify } from '@hungryroot/customer';
import { ROUTE_LOGIN } from '@hungryroot/page-login';

import styles from './ButtonSignupLogin.module.css';

export {
  ButtonProminenceVariant,
  ButtonIconPositionVariant,
  ButtonSizeVariant,
  ButtonStyleVariant,
  ButtonWidthVariant,
} from '@hungryroot/ramen';

export const DEFAULT_LOG_IN_TEXT = 'Log In';
export const DEFAULT_LOG_OUT_TEXT = 'Log Out';

/**
 *
 * This is for the Log In buttons on the homepage
 * There are _a lot_ of hacks in this component because
 * The login modal and the triggers are both controlled by
 * jQuery. We have to make sure the elements are in the DOM
 * and also make sure we don't trigger hydration warnings.
 *
 * Developer beware
 */
export function ButtonSignupLogin(
  props: Pick<ButtonProps, 'style' | 'prominence' | 'size' | 'className'> & {
    loginInText?: string;
    logoutText?: string;
  }
) {
  const {
    logoutText = DEFAULT_LOG_OUT_TEXT,
    loginInText = DEFAULT_LOG_IN_TEXT,
  } = props;
  const customer = useIdentify();
  const className =
    customer.authState === AuthState.isLoading ? styles.loading : '';

  if (customer.authState === AuthState.isLoading) {
    return null;
  }

  if (customer.authState === AuthState.isAuthenticated) {
    return (
      <Button
        key="logout"
        {...props}
        className={`${className} ${props.className}`}
        buttonType="link"
        to="/logout/"
        textPrimary={logoutText}
      />
    );
  }

  return (
    <Button
      key="login"
      {...props}
      buttonType="link"
      to={ROUTE_LOGIN}
      className={`${className} login header-link login-button ${props.className}`}
      textPrimary={loginInText}
    />
  );
}
